<template>
	<div class="">
		<div class="page-content" style="margin-top: 40px;">
			<div style="display: flex;">
				<div
					style="border: solid 1px rgb(24 48 91);min-height: 600px;padding: 20px;width: 75%;margin-right: 2%;">
					<div style="text-align: center;font-size: 22px;color: rgb(24 48 91);;font-weight: 700;">
						{{noticeDetails.fieldA}}
					</div>
					<div style="color: #8b8888;text-align: center;">
						<p style=""><span style="margin-right: 20px;">来源:{{noticeDetails.fieldC}}</span><span
								style="">发布时间:{{noticeDetails.createDate}}</span></p>
					</div>
					<div style="text-align: justify;">
						<p v-html="noticeDetails.fieldI"></p>
					</div>
				</div>
				<div style="width: 25%;">
					<p style="font-weight: bold;">◆ 推荐期刊</p>
					<div style="display: flex;margin-top: 20px;min-height: 40px;"
						v-for="(item,index) in randomJournalList" :key="index">
						<div class="num" style="background-color: #e60012;">{{index+1}}</div>
						<div style="color: #18305b;font-weight: normal;width: 90%;">
							<el-link :underline="false" class="two" href="javascript:void(0);"
								@click="journalDetails(index)">{{item.journalTitle}}</el-link>
						</div>
					</div>
					<el-link :underline="false" href="/journalEi" style="float: right;margin-top: 40px;">查看更多</el-link>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				noticeDetails: {},
				randomJournalList: [],
			};
		},

		methods: {
			journalDetails(index) {
				this.$router.push({
					name: "journalDetails",
					params: this.randomJournalList[index]
				});
			},
			getRandomJournalList() {
				let _this = this
				$http
					.post("randomIamsetJournalList")
					.then(function(res) {
						_this.randomJournalList = res.data
					})
					.catch(function() {});
			},
			// changeNoticeDetails(index){
			// 	this.noticeDetails = this.randomNoticeList[index]
			// 	var timer = setInterval(function() {
			// 		let osTop = document.documentElement.scrollTop || document.body.scrollTop;
			// 		let ispeed = Math.floor(-osTop / 5);
			// 		document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
			// 		this.isTop = true;
			// 		if (osTop === 0) {
			// 			clearInterval(timer);
			// 		}
			// 	}, 50)
			// },
		},
		created() {
			console.log("123456789")
			console.log(this.$route.params)
			this.noticeDetails = this.$route.params
			this.getRandomJournalList()
		},
		mounted() {

		},

	};
</script>

<style lang="scss" scoped>
	.num {
		float: left;
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-color: #ff5722;
		// background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%);
		background-blend-mode: normal, normal;
	}
</style>
